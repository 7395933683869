import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

const WizardPage = ({ data }) => {
  const wizard = data.allPrismicWizard.edges.slice(0, 1).pop()
  if (!wizard) return null
  return (
    <>
      <Helmet>
        <title>Chatchamp | Conversational Commerce Solution</title>
        <meta
          name="description"
          content="Nutze Chatchamps digitale Produktberater (Chatbots) um auf deiner Website deine Kunden ideal beraten zu können. Jederzeit, ohne Mehraufwand. Erhöhe somit deinen Umsatz und Conversionrate."
        />
      </Helmet>
      <div>
        <div className="flex justify-center items-center h-screen">
          <iframe
            width="100%"
            height="100%"
            src={wizard.node.data.iframe.text}
            allowFullScreen
          />
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query WizardPageQuery($uid: String) {
    allPrismicWizard(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          data {
            iframe {
              text
            }
          }
        }
      }
    }
  }
`

export default WizardPage
